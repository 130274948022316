$.fn.toBottom = function() {
    return this.scrollTop(function() {
        return this.scrollHeight;
    });
};

$("[data-chat-channel]").each(function(index, elm) {
    var $elm = $(elm);

    var readUrl = $elm.data("read-url");

    var postRead = function() {
        $.ajax({
            url: readUrl,
            method: "POST",
            success: function(data) {
                $(document).trigger("");
            }
        });
    };
    // postRead();

    var messageTemplate = $elm.find('script[type="text/template"]').html();
    messageTemplate = _.template(messageTemplate);
    var $messages = $elm.find(".requestcard__comments-ul");
    $messages.parent().toBottom();

    $(function() {
        $("#textarea-comment").keypress(function(e) {
            if (e.which == 13 && !e.shiftKey) {
                $(this)
                    .closest("form")
                    .submit();
            }
        });
    });

    $elm.on("submit", ".ajax", function(evt) {
        evt.preventDefault();

        var $form = $(evt.target);
        $.ajax({
            method: $form.attr("method"),
            url: $form.attr("action"),
            data: $form.serialize(),
            success: function(data) {
                $form.find("textarea").val("");
                setTimeout(function(){
                    $form.find("textarea").focus();
                }, 0);
            },
            error: function(xhr, textStatus, errorThrown) {
                try {
                    var json = JSON.parse(xhr.responseText);
                    if (json && json.errors) {
                        var errors = json.errors;
                        for (var key in errors) {
                            if (errors.hasOwnProperty(key)) {
                                alert(errors[key]);
                            }
                        }
                    }
                } catch (e) {
                    console.error(e, arguments);
                }
            },
            complete: function() {
                $form.find("textarea").prop("disabled", false);
            }
        });
        $form.find("textarea").prop("disabled", true);
    });

    var channel = $elm.data("chat-channel");

    var userId = $elm.data("user-id");
    window.Echo.private(channel).listen("NewMessageEvent", data => {
        data.is_own = data.user_id == userId;
        $messages.append(messageTemplate(data));
        $messages.parent().toBottom();

        if (!data.is_own) {
            postRead();
        }
    });
});
