(function($) {
    const cookie = document.cookie;

    if (document.cookie.indexOf("cookie=") < 0) {
        $(".cookie").addClass("cookie--visible");
    }

    $(".cookie").on("click", "button", function(e) {
        e.preventDefault();
        $(".cookie").removeClass("cookie--visible");
        document.cookie = "cookie=true; max-age=31536000;";
    });
})(jQuery);
