<template>
  <li class="c-suggestion" @click="$emit('pick', suggestion)">

    <template v-for="(label, index) in labels">
      <template v-if="index > 0">, </template>
      <span :class="`c-suggestion-label-${label.type}`">{{label.text}}</span>
    </template>
  </li>
</template>

<script>
export default {
  name: "PlaceInputSuggestion",
  props: [
    'suggestion'
  ],
  computed: {
    labels(){
      return [
          'address',
          'city',
          'country'
      ].map(label => {
        const text = this.suggestion.labels[label];
        if(!text){
          return null;
        }
        return {
          type: label,
          text
        };

      })
      .filter(x=>x);
    }
  }
}
</script>

<style scoped>
  /*.suggestion-label-country {*/
  /*  color: red;*/
  /*}*/
</style>
