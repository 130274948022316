$(document).on("click", "[data-favorite-url]", function(evt, ui) {
    evt.preventDefault();

    const $elm = $(evt.target).closest("[data-favorite-url]");

    const url = $elm.data("favorite-url");

    const currentFavorite = $elm.attr("data-favorite-status");
    const newFavorite = currentFavorite == 0;

    const axios = window.axios;

    $elm.attr("data-favorite-status", newFavorite ? 1 : 0);

    axios
        .post(url, {
            favorite: newFavorite
        })
        .then(({ data }) => {
            $elm.attr("data-favorite-status", data.favorite ? 1 : 0);
            if (data.shards) {
                $(document).trigger("shards", data.shards);
            }
        });
});
