(function ($) {

    const $filter = $('.section__filter');
    const $filterbtn = $('.mobile-filter ');
    const $filterclose = $('.filter-header');
    const $filterbtnclose = $('.filter-button');

    function openFilter(){
        $('body').addClass('scrollock');
        $filter.addClass('section__filter--show');
    }

    function closeFilter(){
        $('body').removeClass('scrollock');
        $filter.removeClass('section__filter--show');
    }

    $(document).on('click', '.mobile-filter', function(e){
        openFilter();
    });

    $filterclose.on('click', function(e){
        closeFilter();
    });
    $filterbtnclose.on('click', function(e){
        closeFilter();
    });

})(jQuery);