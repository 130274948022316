(function($) {
    var viewportWidth = $(window).width();

    $("body").on("bless", function(e) {
        const $elm = $(e.target);
        $btncollapse = $elm.find(".btn-collapse");
        $btncollapse.on("click", function(e) {
            e.stopPropagation();
            $btncollapse
                .not(this)
                .find(".el-collapse")
                .removeClass("toggle");
            $(this)
                .find(".el-collapse")
                .toggleClass("toggle");
        });


		$elm.find(".notification-wrapper").each(function(index, elm){
			$(elm).addClass("visible");
			setTimeout(function() {
				$(elm).removeClass("visible");
			}, 6000);
		});

    });

    $(document).on("click", function(e) {
        $(".el-collapse").removeClass("toggle");
    });


})(jQuery);
