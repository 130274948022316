(function($) {

    var $modalNotLoggedIn = $("#modal-not-logged-in");
    var $modalTitle = $modalNotLoggedIn.find('[data-modal-title]');
    var $modalText = $modalNotLoggedIn.find('[data-modal-text]');


    $(document).on("click", ".not-logged-in [data-needs-login]", function(evt) {
        evt.preventDefault();

        var dataContentModalTitle = $(this).attr("data-content-modal-title");
        var dataContentModalText = $(this).attr("data-content-modal-text");

        $modalTitle.text(dataContentModalTitle);
        $modalText.text(dataContentModalText);

        $modalNotLoggedIn.modal("show");
    });

    $modalNotLoggedIn.on("hidden.bs.modal", function() {
        $modalTitle.empty();
        $modalText.empty();
    });

    $(document).on("click", ".button-modal-stek-delete", function(evt) {
        evt.preventDefault();
        $("#modal-stek-delete").modal("show");
    });

    $(document).on("click", ".button-modal-user-delete", function(evt) {
        evt.preventDefault();
        $("#modal-user-delete").modal("show");
    });


    // Tijdelijke code om modal even via knoppie te kunnen showen
    // TODO weghalen zodra modal automatisch word getoond, zie ticket labs/stek#242
    const $buttonModalUsersInfo = document.querySelector('a[data-button-modal-users-info]');

    if($buttonModalUsersInfo != null) {
        $buttonModalUsersInfo.addEventListener('click', evt => {
            evt.preventDefault();
            $('[data-modal-users-info]').modal("show");
        });
    };
    // //

	$('[data-modal-users-info]').modal("show");

})(jQuery);
