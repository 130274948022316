import Echo from "laravel-echo";

window.Pusher = require('pusher-js');

// Pusher.log = function(msg) {
//     console.log(msg);
// };



if (document.getElementById('echo-config')) {
    const config = JSON.parse(document.getElementById('echo-config').textContent);

    console.log(document.getElementById('echo-config').textContent);
    const options = {
        broadcaster: 'pusher',
        key: config.key || '1',
        wsHost: config.host || window.location.hostname,
        wsPort: config.port || 6001,
        forceTLS: false,
        disableStats: true,
        auth: {
            headers: config.headers ?? {},
        },
        enabledTransports: ['ws', 'wss'],
    };

    window.Echo = new Echo(options);


}else{
    console.log('No config');
}

// window.Echo = new Echo({
//     broadcaster: "socket.io",
//     host:
//         window.location.hostname +
//         (/(beta\.)?stek\.app/.test(window.location.hostname) ? "" : ":6001/"),
//     client: client
// });
