<template>
  <div class="place-input-wrapper" :class="{'show-map': showMap}">
    <label id="label">
      {{location || 'Plaats'}}
    </label>
    <input
        v-model="query"
        ref="input"
        id="location"
        type="text"
        placeholder="Plaats"
        class="location-input"
        autocomplete="off"
        @focus="onFocus"
        @blur="onBlur"
    />
    <input
        type="hidden"
        name="location"
        v-model="location"
    />
    <input
        v-model="value"
        ref="value"
        type="hidden"
        name="latlng"
    />
    <ul
        v-if="focussed"
        class="c-list-suggestions"
        :class="{loading, empty: options.length <= 0}"
    >
      <li class="c-suggestion-helptext">
        Waar zoek jij een stek?
      </li>
      <PlaceInputSuggestion
          v-for="(suggestion, index) in options"
          :key="`suggestion-${index}`"
          :suggestion="suggestion"
          @pick="pickSuggestion(suggestion)"
      />
      <li v-if="loading" class="c-suggestion-loading">
        Aan het laden...
      </li>
      <li v-if="!loading && query && options.length <= 0" class="c-suggestion-no-results">
        Niets gevonden.
      </li>
    </ul>

    <GmapMap
        v-if="showMap && center"
        :center="mapCenter"
        style="width:100%;  height: 400px;"
        :zoom="14"
    >
      <GmapMarker
          v-if="valueAsLatLng"
          :position="valueAsLatLng"
          :draggable="true"
          @click="center=valueAsLatLng"
          @drag="updateFromMarker"
      />
    </GmapMap>
  </div>
</template>

<script>
import axios from "axios";
import {debounce} from "lodash";
import PlaceInputSuggestion from "./PlaceInputSuggestion";

let controller = new AbortController();

export default {
  name: "PlaceInput",
  components: {
    PlaceInputSuggestion,

  },
  props: {
    latlng: String,
    text: String,
    showMap: {
      type: [Boolean, String],
      default: false,
    }
  },
  data(){
    return {
      options: [],
      focussed: false,
      loading: false,
      showOptions: false,
      suppressSearch: false,
      query: this.text,
      location: this.text,
      value: this.latlng,
      currentQuery: null,
      mapCenter: null,
      blurTimeout: null,
    }
  },
  methods: {
    search(){
      controller.abort();

      this.options = [];


      // if(this.query.length < 1){
        this.value = '';
        this.location = '';
        // return;
      // }

      this.showOptions = true;
      this.loading = true;

      controller = new AbortController();
      axios.get('/api/v1/geocode', {
        signal: controller.signal,
        params: {
          search: this.query
        }
      })
      .then((results) => {
        this.loading = false;
        this.options = results.data.map(suggestion => {

          let address = null;
          if (suggestion.streetName) {
            address = suggestion.streetName;
          // } else if (suggestion.postalCode) {
          //   address = suggestion.postalCode;
          }

          if (suggestion.streetNumber) {
            address += ' ' + suggestion.streetNumber;
          }

          let city = [
            suggestion.subLocality,
            suggestion.locality
          ].filter(x=>x);

          if (city.length == 0 && suggestion.adminLevels) {
            const lvls = Object.values(suggestion.adminLevels);
            city = [lvls[lvls.length - 1].name];
          }

          suggestion.labels = {
            address: address,
            city: city.join(', '),
            country: suggestion.countryCode,
          };
          suggestion.label = Object.values(suggestion.labels).filter(x=>x).join(', ');
          return suggestion;
        });
      })
      .finally(() => {
        this.currentQuery = null;
      })
      .catch((e) => {
        if(e.message == 'canceled'){
          return;
        }
        this.loading = false;
        console.log(e);
      })
      ;
    },
    updateFromMarker(location){
      this.valueAsLatLng = location.latLng;
    },
    onFocus(){
      if(this.blurTimeout){
        clearTimeout(this.blurTimeout);
      }
      this.focussed = true;
    },
    onBlur(){
      if(this.blurTimeout){
        clearTimeout(this.blurTimeout);
      }
      this.blurTimeout = setTimeout(()=>{
        this.focussed = false;
        if(!this.location){
          this.query = '';
        }
      }, 200);
    },
    clear(){
      this.options = [];
      this.latlng = '';
      this.showOptions = false;
    },
    pickSuggestion(suggestion){
      this.valueAsLatLng = suggestion;

      this.showOptions = false;

      this.suppressSearch = true;
      this.query = suggestion.label;
      this.location = suggestion.label;

      this.mapCenter = this.center;

      setTimeout(() => {
				this.$refs.input.focus();
        this.suppressSearch = false;
      }, 0);

    }
  },
  computed: {
    valueAsLatLng: {
      get() {
        const parts = this.value.split(/, ?/);
        if (parts.length < 2) {
          return null;
        }
        return {
          lat: parseFloat(parts[0]),
          lng: parseFloat(parts[1])
        };
      },
      set(value){
        const lat = value.lat ?? value.latitude;
        const lng = value.lng ?? value.longitude;

        this.value = [(lat instanceof Function ? lat() : lat), (lng instanceof Function ? lng() : lng)].join(',');
      }
    },
    center(){
      const result = this.valueAsLatLng;
      if(!result){
        //Utrecht
        return {
          lat: 52.09,
          lng: 5.11
        };
      }
      return result;
    }
  },
  watch: {
    query() {
      if(this.suppressSearch){
        return;
      }
      this.debouncedSearch();
    },
    value(){
      setTimeout(() => {
        this.$refs.value.dispatchEvent(new Event("change"));
      }, 10);
    }
  },
  created(){
    this.debouncedSearch = debounce(() => {
      this.search();
    }, 400);
    this.mapCenter = this.center;
  },
}

</script>

<style scoped>
  /*.place-input-wrapper {*/
  /*  position: relative;*/
  /*  height: 50px;*/
  /*  width: 100%;*/
  /*}*/

  /*.place-input-wrapper.show-map {*/
  /*  height: 470px;*/
  /*  padding-right: 20px;*/
  /*}*/

  /*.place-input-wrapper.show-map .vue-map-container {*/
  /*  margin-top: 50px;*/
  /*}*/

  /*.c-list-suggestions {*/
  /*  z-index: 100;*/
  /*}*/

  /*.c-list-suggestions li {*/
  /*  padding-left: 13px;*/
  /*  height: 49px;*/
  /*}*/

  /*#location {*/
  /*  opacity: 0;*/
  /*}*/

  /*#location:focus {*/
  /*  opacity: 1.0;*/
  /*}*/

  /*#label, #location {*/
  /*  width: 100%;*/
  /*  border: 0;*/
  /*  position: absolute;*/
  /*}*/

  /*#label {*/
  /*  line-height: 50px;*/
  /*}*/

  /*.place-input-wrapper:focus-within #label {*/
  /*  display: none;*/
  /*}*/


</style>
