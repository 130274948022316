(function ($) {
	$('form').on('submit', function (evt) {

		const $form = $(this);

		if ($form.hasClass('submitted')) {
			evt.preventDefault();
		} else {
			$form.addClass('submitted');
		}

		$form.find('[data-submitted]').each(function (index, elm) {
			const $btn = $(elm);
			const text = $btn.data('submitted');
			$btn.text(text);

			$btn.attr('aria-disabled', 'true');
		});

	});

	$('.btn-copy').each(function (index, elm) {
		const $btn = $(elm);
		const $tooltip = $btn.find('.tooltipishtext');
		const orig = $tooltip.text();

		$btn.on("mouseout", function () {
			$tooltip.html(orig);
		});

		$btn.on('click', function (e) {
			e.preventDefault();
			const targetUrl = $(this).data('copy-text');
			copyClipboardAction(targetUrl);

			$tooltip.text($tooltip.data('feedback'));
		});
	});


	function copyClipboardAction(copyValue) {
		// we need to create element or use existing element but should n't visible to user.
		var hiddenEleForCopy = $('#_hiddenEleForCopy_');
		// Checking Element exists or not
		if (!hiddenEleForCopy.length) {
			$('body').append('<input style="position:absolute;top: -9999px;" id="_hiddenEleForCopy_" value=""></input>');
			hiddenEleForCopy = $('#_hiddenEleForCopy_');
		}
		hiddenEleForCopy.val(copyValue);
		hiddenEleForCopy.select();
		document.execCommand('copy');
		document.getSelection().removeAllRanges();
	}

})(jQuery);
