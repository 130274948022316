(function($) {
    $.fn.resetFormElement = function() {
        this.wrap("<form>")
            .closest("form")
            .get(0)
            .reset();
        this.unwrap();

        return this;
    };

    $(document).on("bless", function(evt, data) {
        var $elm = $(evt.target);

        $elm.find(".image-upload").each(function() {
            var $wrapper = $(this);
            var $file = $wrapper.find("input[type=file][data-auto-upload]");
            var $uploadButton = $wrapper.find(".image-upload__select-file");

            $images = $wrapper.find(".image-upload__images");
            var placeholderTemplate = $wrapper
                .find("script[name=placeholder]")
                .html();
            var previewTemplate = $wrapper.find("script[name=preview]").html();

            $uploadButton.on("click", function(e) {
                $file.trigger("click");
                e.preventDefault();
            });

            $wrapper.on("change", "input[type=hidden]", function(evt) {
                var $input = $(evt.target);
                var $placeholder = $input.closest(".image-placeholder");

                var $imageWrapper = $placeholder.find(
                    ".image-upload__preview-image"
                );

                var val = $input.val();

                if (val == "") {
                    $imageWrapper.css({ "background-image": "none" });
                } else {
                    var url = $file
                        .data("preview-url")
                        .replace("/id", "/" + val);
                    $imageWrapper.css({
                        "background-image": "url(" + url + ")"
                    });
                }
            });

            $wrapper.on("change", "input[type=file]", function() {
                $($file[0].files).each(function(index, file) {
                    if (!$file.attr("multiple")) {
                        $images.html("");
                    }

                    var $preview = $(previewTemplate);
                    $images.append($preview);
                    var $input = $preview.find("input[type=hidden]");

                    var fd = new FormData();
                    fd.append($file.attr("name"), file);

                    $wrapper.addClass("image-upload--uploading");
                    $wrapper.removeClass("image-upload--error");

                    $.ajax({
                        url: $file.data("auto-upload"),
                        data: fd,
                        processData: false,
                        contentType: false,
                        headers: {
                            Accept: "application/json"
                        },
                        type: "POST",
                        success: function(data) {
                            setTimeout(function() {
                                $input.val(data.id).change();
                                $wrapper.removeClass("image-upload--uploading");
                                $wrapper.addClass("image-upload--uploaded");
                            }, 1000);
                        },
                        complete: function() {},
                        error: function(xhr) {
                            $wrapper.trigger("initialState");
                            $wrapper.addClass("image-upload--error");
                            if (xhr.responseJSON) {
                                $error.text(xhr.responseJSON.message);
                            }
                        }
                    });
                });

                $file.resetFormElement();
            });

            $wrapper.on("initialState", function() {
                $wrapper.removeClass("image-upload--uploading");
                $wrapper.removeClass("image-upload--error");
                $wrapper.removeClass("image-upload--uploaded");
                $images.html("");
                var $placeholder = $(placeholderTemplate);
                $images.append($placeholder);
            });

            $wrapper.on("click", ".image-upload__remove-file", function(evt) {
                $wrapper.trigger("initialState");
            });
        });
    });
})(jQuery);
