import { debounce } from "lodash";

(function($) {
    var suppressHashChange = false;

    var prevquery = null;

    if (window.location.hash == "#restore") {
        var storedQuery = sessionStorage.getItem("prevquery") || "";
        window.location.hash = storedQuery;
    }

    $(window).bind("querychange", function(e) {
        var query = window.location.hash;
        if (query) {
            query = query.substr(1);
        }

        if (query === prevquery) {
            return;
        }

        sessionStorage.setItem("prevquery", query);

        prevquery = query;
        var vars = query.split("&");

        var $element = $('input[type="checkbox"], input[type="radiobutton"]');
        $element.prop("checked", false);

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            var name = decodeURIComponent(pair[0]);
            var value = decodeURIComponent(pair[1]);

            var $elm = $("input, select").filter(function(index, e) {
                if ($(e).is(":checkbox") || $(e).is(":radio")) {
                    return (
                        $(e).attr("name") == name && $(e).attr("value") == value
                    );
                }
                return $(e).attr("name") == name;
            });

            if ($elm.is(":checkbox") || $(e).is(":radio")) {
                $elm.prop("checked", true);
            } else {
                $elm.val(value);
            }

            if (name == "l") {
                $(".ap-icon-clear").toggle(!!value);
            }

            if (name == "query") {
                oldSearch = value;
            }
        }

        $("#search").trigger("submit");
    });

    $("body").on("click", "#search .pagination .page-link", function(e) {
        e.preventDefault();

        var $elm = $(e.target);
        var url = $elm.attr("href");
        if (url) {
            var parts = new URL(url, window.location);
            var page = parts.searchParams.get("page");

            var $paginationInput = $("#search input[type=hidden][name=page]");
            $paginationInput.val(page);
            $("#search").submit();
        }
        return false;
    });

    function activate_searchbar() {
        if (window.innerWidth < 992) {
            $(".searchbar__main").addClass("searchbar--active");
            $(".search-input").focus();
            $("body").addClass("scrollock");
        } else {
            $(".searchbar__main").addClass("bg-white searchbar--active");
            $("#page-overlay").addClass("page-overlay--show");
        }
    }

    function deactivate_searchbar() {
        if (window.innerWidth < 992) {
            $(".searchbar__main").removeClass("searchbar--active");
            $("body").removeClass("scrollock");
        } else {
            $(".searchbar__main").removeClass("bg-white searchbar--active");
            $("#page-overlay").removeClass("page-overlay--show");
        }
    }

    $(document).on("submit", "#search", function(e) {
        e.preventDefault();
        submit_searchform(e.target);
        deactivate_searchbar();
    });

    let oldSearch = "";

    const submit_searchform = debounce(
        function(elm) {
            let $elm = $(elm);
            let url = $elm.prop("action");

            $(".cardcontainer").addClass("loading");

            let formattedQuery = $(".search-input").val();
            if ($(".location-input").val() != "") {
                if (formattedQuery == "") {
                    formattedQuery = $(".location-input").val();
                } else {
                    formattedQuery =
                        formattedQuery + ", " + $(".location-input").val();
                }
            }

            if ($(".select-distance").val() >= 2000) {
                formattedQuery =
                    formattedQuery +
                    " " +
                    $(".select-distance option:selected").text();
            }

			const $searchbar = $(".searchbar-placeholder span");
			$searchbar.toggleClass('placeholder-filled', formattedQuery.length);

			let searchbarText = formattedQuery;

			if (!searchbarText) {
				searchbarText = $searchbar.data('placeholder');
			}

			$searchbar.text(searchbarText);


            if ($(".search-input").val() != oldSearch) {
                $elm.find("[name=page]").val(1);
            }
            oldSearch = $(".search-input").val();

            const formdata = $elm.serialize();
            const query = formdata;

            if (window.location.hash.substr(1) != query) {
                suppressHashChange = true;
                window.location.hash = query;
                suppressHashChange = false;
            }

            $.ajax({
                url: url,
                data: formdata
            }).done(function(data) {
                $(".cardcontainer").replaceWith(data);
                $(".cardcontainer").removeClass("loading");
                elm.scrollIntoView();

                $('#search').removeClass('submitted');
                //$(".cardcontainer").trigger("bless");
            });
        },
        100,
        false
    );

    $("body").on("bless", function(e) {
        var $elm = $(e.target);
        var $filter = $elm.find("#filter");
        var $search = $elm.find("#search");
        var $sortItems = $elm.find(".sort-items");

        $filter.on("change", "input", function() {

            console.log("input change event");
            //reset the page to 1 when changing search filters
            $search.find("input[name=page]").val(1);

            //submit the form
            $filter.closest("form").submit();
        });

        $sortItems.on("change", "input", function() {
            $sortItems.closest("form").submit();
        });

        if (window.innerWidth < 992) {
            $(".searchbar-placeholder").on("click", function() {
                activate_searchbar();
            });
            $(".searchbar__mobileheader").on("click", function() {
                deactivate_searchbar();
            });
        } else {
            $(".searchbar__main").on("click", function() {
                activate_searchbar();
            });
            $("#page-overlay").on("click", function() {
                deactivate_searchbar();
            });
        }
    });

    $("body").one("bless", function() {
        if (window.location.hash) {
            $(window).trigger("querychange");
        } else {
            var query = $("#search").serialize();
            history.replaceState({}, "", "#" + query);
        }
    });

    window.onhashchange = function() {
        if (suppressHashChange) {
            return;
        }
        $(window).trigger("querychange");
    };

})(jQuery);
