/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import Vue from "vue";

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    // Look for files in the current directory
    './vue',
    // Do not look in subdirectories
    true,
    // Only include "_base-" prefixed .vue files
    /[\w-]+\.vue$/
)

// For each matching file name...
requireComponent.keys().forEach((fileName) => {
    // Get the component config
    const componentConfig = requireComponent(fileName)
    // Get the PascalCase version of the component name
    const componentName = fileName
        // Remove the "./" from the beginning
        .replace(/^\.\//, '')
        // Remove the file extension from the end
        .replace(/\.\w+$/, '')
        // Split up kebabs
        .split('-')
        // Upper case
        .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
        // Concatenated
        .join('')
        .toLowerCase()

    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig)
})


import axios from "axios";
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.MIX_GOOGLE_MAPS_API_KEY,
        libraries: '',
    }
});

const app = new Vue({
    el: 'body>main',
});


require("./bootstrap");

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
});

require("./components/images");
require("./components/image-upload");
require("./components/position");
require("./components/collapsing");
require("./components/modal");
require("./components/realtime");
require("./components/chat");
require("./components/trade-requests");
require("./components/shards");
require("./components/buttons");
require("./components/selectize");
require("./components/filter");
require("./components/searchbar");
require("./components/cookie");
require("./components/favorite");

window.moment = require("moment");

$(document).ready(function() {
    $("body").trigger("bless");
});

(function($) {
    var $requestsUl = $(".requests__ul");
    if ($requestsUl.length) {
        var $requestsLiActive = $requestsUl.find("li.requests__li.active");

        if ($requestsLiActive.length) {
            // $requestsLiActive[0].scrollIntoView();
            $requestsUl.scrollTop($requestsLiActive.position().top - 62);
        }
    }
})(jQuery);
