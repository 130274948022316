var userChannel = $("body").data("user-channel");

if (userChannel) {
    window.Echo.private(userChannel).listen(
        "TradeRequestUpdatedEvent",
        data => {
            $(document).trigger("shards", data.shards);

            if (data.unread_count && data.user != data.comment_user_id) {
                $("#trade-request-" + data.trade_request_id).addClass("unread");
                $("#trade-request-" + data.trade_request_id + "-unread").html(
                    data.unread_comments
                );
            } else {
                $("#trade-request-" + data.trade_request_id).removeClass(
                    "unread"
                );
                $("#trade-request-" + data.trade_request_id + "-unread").html(
                    ""
                );
            }
        }
    );

    window.Echo.private(userChannel).listen("NewMessageEvent", data => {
        $("#trade-request-" + data.trade_request_id + "-last-comment").html(
            data.comment
        );
        $(
            "#trade-request-" + data.trade_request_id + "-last-comment-update"
        ).html("nu");
    });
}
