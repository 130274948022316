(function ($) {
    if ($("input[name=location]").val() != "") {
        $("input[name=location]").prop("readonly", false);
    }

    $("[data-position]").on(
        "change",
        "input[name=postcode], input[name=number]",
        function () {
            var $wrapper = $(this).closest("[data-position]");
            var url = $(this)
                .closest("[data-geocoding-url]")
                .data("geocoding-url");
            var $elm = $(this);
            var role = $elm.data("role");

            $wrapper.find("input[name=lat]").val(null);
            $wrapper.find("input[name=long]").val(null);

            if (role == "postcode" || role == "number") {
                var postcode = $("[data-role=postcode]").val();
                var number = $("[data-role=number]").val();

                if (postcode && number) {
                    $wrapper
                        .find("input[name=postcode], input[name=number]")
                        .prop("readonly", true);

                    $.ajax(url, {
                        method: "POST",
                        data: {
                            postcode: postcode,
                            number: number
                        },
                        success: function (data) {
                            if (data.error) {
                                $wrapper
                                    .find(".location_error")
                                    .html(data.error);
                                $wrapper.find("input").prop("readonly", false);
                            } else if (data.lat && data.long) {
                                $wrapper.find(".location_error").html("");
                                $wrapper.find("input[name=lat]").val(data.lat);
                                $wrapper
                                    .find("input[name=long]")
                                    .val(data.long)
                                    .trigger("change");
                                $wrapper
                                    .find("input[name=location]")
                                    .val(data.location);
                            }
                        },
                        complete: function () {
                            $wrapper.find("input").prop("readonly", false);
                        }
                    });
                }
            }
        }
    );
})(jQuery);
